<template>
  <div>
    <div style="margin-bottom: 18px; margin-top: 18px">
      <a-select v-model="record_type" style="width: 120px">
        <a-select-option value="0">日记</a-select-option>
        <a-select-option value="1">周记</a-select-option>
        <a-select-option value="2">月记</a-select-option>
        <a-select-option value="3">年记</a-select-option>
      </a-select>
      <a-select v-model="content_type" style="width: 120px; margin-left: 10px">
        <a-select-option value="0">工作</a-select-option>
        <a-select-option value="1">生活</a-select-option>
      </a-select>
      <span v-if="this.create_time !== ''" style="margin-left: 10px; color: red"
        >创建时间: {{ changeTime(this.create_time) }}</span
      >
      <span v-if="this.update_time !== ''" style="margin-left: 10px; color: red"
        >最后更改时间: {{ changeTime(this.update_time) }}</span
      >
      <a-button
        type="primary"
        icon="save"
        style="float: right; margin-right: 10px"
        @click="save"
        >保存</a-button
      >
    </div>
    <quill-editor
      ref="myQuillEditor"
      v-model="content"
      :options="editorOption"
      class="editor"
      @blur="onEditorBlur($event)"
      @focus="onEditorFocus($event)"
      @ready="onEditorReady($event)"
      @change="onEditorChange($event)"
    />
    <form
      action
      method="post"
      enctype="multipart/form-data"
      id="uploadFormMulti"
    >
      <input
        style="display: none"
        :id="uniqueId"
        type="file"
        name="file"
        multiple
        accept="image/jpg, image/jpeg, image/png, image/gif"
        @change="uploadImg('uploadFormMulti')"
      />
    </form>
  </div>
</template>

<script>
import { toolbarOptions } from "@/libs/editor";
import { Quill } from "vue-quill-editor";
import imageResize from "quill-image-resize-module";
import { ImageDrop } from "quill-image-drop-module";
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", imageResize);
import axios from "axios";
import {
  getDetailRecord,
  changeRecord,
  saveRecord,
  mergeRecord,
} from "@/api/daily_life";
import { changeTime } from "@/libs/utils";
import { Modal } from "ant-design-vue";
export default {
  data() {
    this.changeTime = changeTime;
    return {
      isChange: false,
      uniqueId: "uniqueId",
      content: "", // 富文本编辑器默认内容
      record_id: "",
      record_type: "0",
      content_type: "0",
      create_time: "",
      update_time: "",
      editorOption: {
        modules: {
          imageDrop: true,
          imageResize: {
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white",
            },
            modules: ["Resize", "DisplaySize", "Toolbar"],
          },
          toolbar: toolbarOptions,
        },
        theme: "snow",
        placeholder: "请输入正文",
      },
    };
  },
  computed: {
    // 当前富文本实例
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
  },
  methods: {
    leavePage(e) {
      e.returnValue = "";
    },
    save() {
      var data = {
        record_content: this.content,
        record_type: parseInt(this.record_type),
        content_type: parseInt(this.content_type),
      };
      // 更改
      if (this.record_id !== "") {
        changeRecord(this.record_id, data)
          .then((res) => {
            if ("record_id" in res.data) {
              this.$message.success("保存成功!");
              this.content = res.data.record_content;
              this.record_id = res.data.record_id;
              this.record_type = res.data.record_type.toString();
              this.content_type = res.data.content_type.toString();
              this.create_time = res.data.create_time;
              this.update_time = res.data.update_time;
            } else {
              this.$message.error(
                "同时间段内已存在相同属性的记录, 故无法更改!"
              );
            }
          })
          .catch((err) => {
            this.$message.error(err);
          });
      } else {
        // 编辑
        saveRecord(data)
          .then((res) => {
            if ("record_id" in res.data) {
              this.$message.success("保存成功!");
              this.content = res.data.record_content;
              this.record_id = res.data.record_id;
              this.record_type = res.data.record_type.toString();
              this.content_type = res.data.content_type.toString();
              this.create_time = res.data.create_time;
              this.update_time = res.data.update_time;
              this.$router.push({
                path: "/daily_life/edit",
                query: { record_id: this.record_id },
              });
            } else {
              // 需要合并
              Modal.confirm({
                title: "警告",
                content: "同时间段内存在相同属性的记录,您要将两者合并吗?",
                onOk: () => {
                  mergeRecord(data)
                    .then((res) => {
                      this.$message.success("保存成功!");
                      this.content = res.data.record_content;
                      this.record_id = res.data.record_id;
                      this.record_type = res.data.record_type.toString();
                      this.content_type = res.data.content_type.toString();
                      this.create_time = res.data.create_time;
                      this.update_time = res.data.update_time;
                      this.$router.push({
                        path: "/daily_life/edit",
                        query: { record_id: this.record_id },
                      });
                    })
                    .catch((err) => {
                      this.$message.error(err);
                    });
                },
                onCancel() {},
              });
            }
          })
          .catch((err) => {
            this.$message.error(err);
          });
      }
    },
    // 失去焦点事件
    onEditorBlur(quill) {
      console.log("editor blur!", quill);
    },
    // 获取焦点事件
    onEditorFocus(quill) {
      console.log("editor focus!", quill);
    },
    // 准备富文本编辑器
    onEditorReady(quill) {
      console.log("editor ready!", quill);
    },
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
    },
    uploadImg: async function () {
      var _this = this;
      // 构造formData对象
      var formData = new FormData();
      var file = document.getElementById(_this.uniqueId).files[0];
      if (!file) {
        return;
      }
      formData.append("file", file);
      // 上传
      const baseUrl =
        process.env.NODE_ENV === "development"
          ? "http://dev-www.lcttty.top:8000/api/daily_life/upload"
          : "https://www.lcttty.top/api/daily_life/upload";
      axios
        .post(baseUrl, formData)
        .then((res) => {
          if (res.data.code === "400") {
            this.$message.error(res.data.result);
          } else {
            //上传文件成功之后在富文本中回显(显示)
            let Range = _this.editor.getSelection();
            _this.editor.insertEmbed(
              Range != null ? Range.index : 0,
              "image",
              res.data.result
            );
          }
          //成功之后,将文件的文本框的value置空
          document.getElementById(_this.uniqueId).value = "";
        })
        .catch((err) => {
          document.getElementById(_this.uniqueId).value = "";
          this.$message.error(err);
        });
    },
  },
  mounted() {
    window.addEventListener("beforeunload", this.leavePage);
    var _this = this;
    var imgHandler = async function (image) {
      if (image) {
        var fileInput = document.getElementById(_this.uniqueId); //隐藏的file文本ID
        fileInput.click(); // 加一个触发事件
      }
    };
    _this.editor.getModule("toolbar").addHandler("image", imgHandler);
    // 获取初始值
    if ("record_id" in this.$route.query) {
      var record_id = this.$route.query.record_id;
      getDetailRecord(record_id)
        .then((res) => {
          this.content = res.data.record_content;
          this.record_id = res.data.record_id;
          this.record_type = res.data.record_type.toString();
          this.content_type = res.data.content_type.toString();
          this.create_time = res.data.create_time;
          this.update_time = res.data.update_time;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    }
  },
  beforeRouteLeave(to, from, next) {
    Modal.confirm({
      title: "警告",
      content: "您确定要离开此页面?",
      onOk: () => {
        next();
      },
      onCancel() {},
    });
  },
  destroyed() {
    window.removeEventListener("beforeunload", this.leavePage);
  },
};
</script>

<style lang="scss">
.editor {
  line-height: normal !important;
  height: 400px;
}
.ql-snow .ql-tooltip[data-mode="link"]::before {
  content: "请输入链接地址:";
}
.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0px;
  content: "保存";
  padding-right: 0px;
}

.ql-snow .ql-tooltip[data-mode="video"]::before {
  content: "请输入视频地址:";
}
.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: "14px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
  content: "10px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
  content: "18px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
  content: "32px";
}
.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: "文本";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: "标题1";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: "标题2";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: "标题3";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: "标题4";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  content: "标题5";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  content: "标题6";
}
.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: "标准字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
  content: "衬线字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
  content: "等宽字体";
}
</style>
