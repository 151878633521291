import axios from "@/libs/api.request";

export const getRecord = (query) => {
  return axios.request({
    url: "/daily_life/record/",
    method: "get",
    params: query,
  });
};

export const getDetailRecord = (record_id) => {
  return axios.request({
    url: "/daily_life/record/" + record_id + "/",
    method: "get",
  });
};

export const changeRecord = (record_id, reqdata) => {
  return axios.request({
    url: "/daily_life/record/" + record_id + "/",
    method: "put",
    data: reqdata,
  });
};

export const saveRecord = (reqdata) => {
  return axios.request({
    url: "/daily_life/record/",
    method: "post",
    data: reqdata,
  });
};

export const mergeRecord = (reqdata) => {
  return axios.request({
    url: "/daily_life/record/record_merge/",
    method: "post",
    data: reqdata,
  });
};

export const deleteRecord = (record_id) => {
  return axios.request({
    url: "/daily_life/record/" + record_id + "/",
    method: "delete",
  });
};

export const addIcon = (icon_name) => {
  return axios.request({
    url: "/daily_life/icon_library/",
    method: "post",
    data: { icon_name: icon_name },
  });
};

export const getIcon = (query) => {
  return axios.request({
    url: "/daily_life/icon_library/",
    method: "get",
    params: query,
  });
};

export const addAccountIcon = (reqData) => {
  return axios.request({
    url: "/daily_life/account_icon/",
    method: "post",
    data: reqData,
  });
};

export const getAccountIcon = (query) => {
  return axios.request({
    url: "/daily_life/account_icon/",
    method: "get",
    params: query,
  });
};

export const DeleteAccountIcon = (id) => {
  return axios.request({
    url: "/daily_life/account_icon/" + id + "/",
    method: "delete",
  });
};

export const addAccount = (reqData) => {
  return axios.request({
    url: "/daily_life/account/",
    method: "post",
    data: reqData,
  });
};

export const getAccount = (query) => {
  return axios.request({
    url: "/daily_life/account/",
    method: "get",
    params: query,
  });
};
